import Column from '@/model/shared/Column';
import Tab from '@/model/shared/Tab';
import { ROLES } from '@/model/shared/roles';
import { basicInfo as createBasicInfo } from '@/router/private/modules/ecommerce/admin/fraudTemplates/formCreate/basicInfo';
import { fraud as createFraud } from '@/router/private/modules/ecommerce/admin/fraudTemplates/formCreate/fraud';
import { basicInfo as editBasicInfo } from '@/router/private/modules/ecommerce/admin/fraudTemplates/formEdit/basicInfo';
import { fraud as editForm } from '@/router/private/modules/ecommerce/admin/fraudTemplates/formEdit/fraud';
import { basicInfo as duplicateBasicInfo } from '@/router/private/modules/ecommerce/admin/fraudTemplates/formDuplicate/basicInfo';
import { fraud as duplicateFraud } from '@/router/private/modules/ecommerce/admin/fraudTemplates/formDuplicate/fraud';

// Custom template, la template custom es una template por defecto, que no se puede editar.
export const CUSTOM_TEMPLATE = 'Custom';
const COLUMNS = {
  ACTIONS: new Column('ACTIONS', 'actions').setClass('w-4'),
  NAME: new Column('NAME', 'name')
    .asSort()
    .setClass('font-bold truncate')
    .setAllowedRoles(
      ROLES.ADMIN,
      ROLES.CLIENT,
      ROLES.PUBLISHER,
      ROLES.AD_OPS,
      ROLES.SUPER_ADVERTISER,
      ROLES.ADVERTISER
    ),
  CREATION_DATE: new Column('CREATION DATE', 'creationDate')
    .asSort()
    .setClass('w-10')
    .setAllowedRoles(
      ROLES.ADMIN,
      ROLES.CLIENT,
      ROLES.PUBLISHER,
      ROLES.AD_OPS,
      ROLES.SUPER_ADVERTISER,
      ROLES.ADVERTISER
    ),
  OFFERS_LINKED: new Column('OFFERS LINKED', 'countOffers')
    .asSort()
    .setClass('w-8')
    .toRight()
    .setAllowedRoles(
      ROLES.ADMIN,
      ROLES.CLIENT,
      ROLES.PUBLISHER,
      ROLES.AD_OPS,
      ROLES.SUPER_ADVERTISER,
      ROLES.ADVERTISER
    ),
};

export const TABLE_CONFIG = {
  actions: [{ name: 'Edit' }, { name: 'Duplicate' }, { name: 'Delete' }],
  defaultColumns: [COLUMNS.NAME, COLUMNS.OFFERS_LINKED, COLUMNS.CREATION_DATE, COLUMNS.ACTIONS],
};

export const TABS_CONFIG = {
  new: {
    tabs: [new Tab('Basic Info', createBasicInfo, false, false), new Tab('Fraud', createFraud, false, false)],
  },
  edit: {
    tabs: [new Tab('Basic Info', editBasicInfo, false, false), new Tab('Fraud', editForm, false, false)],
  },
  duplicate: {
    tabs: [new Tab('Basic Info', duplicateBasicInfo, false, false), new Tab('Fraud', duplicateFraud, false, false)],
  },
};
