export default Object.freeze({
  lengthOfTimeOptions: [
    { name: '12 hours', value: 43200 },
    { name: '1 day', value: 86400 },
    { name: '7 day', value: 604800 },
    { name: '15 day', value: 1296000 },
    { name: '30 day', value: 2592000 },
  ],
  attributionTimeOut: [
    { name: '0 (unlimited)', value: 0 },
    { name: '5 (minutes)', value: 300 },
    { name: '10 (minutes)', value: 600 },
    { name: '15 (minutes)', value: 900 },
    { name: '20 (minutes)', value: 1200 },
    { name: '25 (minutes)', value: 1500 },
    { name: '30 (minutes)', value: 1800 },
    { name: '35 (minutes)', value: 2100 },
    { name: '40 (minutes)', value: 2400 },
    { name: '45 (minutes)', value: 2700 },
    { name: '50 (minutes)', value: 3000 },
    { name: '55 (minutes)', value: 3300 },
    { name: '1 (hours)', value: 3600 },
    { name: '2 (hours)', value: 7200 },
    { name: '3 (hours)', value: 10800 },
    { name: '4 (hours)', value: 14400 },
    { name: '5 (hours)', value: 18000 },
    { name: '6 (hours)', value: 21600 },
    { name: '7 (hours)', value: 25200 },
    { name: '8 (hours)', value: 28800 },
    { name: '9 (hours)', value: 32400 },
    { name: '10 (hours)', value: 36000 },
    { name: '11 (hours)', value: 39600 },
    { name: '12 (hours)', value: 43200 },
    { name: '13 (hours)', value: 46800 },
    { name: '14 (hours)', value: 50400 },
    { name: '15 (hours)', value: 54000 },
    { name: '16 (hours)', value: 57600 },
    { name: '17 (hours)', value: 61200 },
    { name: '18 (hours)', value: 64800 },
    { name: '19 (hours)', value: 68400 },
    { name: '20 (hours)', value: 72000 },
    { name: '21 (hours)', value: 75600 },
    { name: '22 (hours)', value: 79200 },
    { name: '23 (hours)', value: 82800 },
    { name: '1 (days)', value: 86400 },
    { name: '2 (days)', value: 172800 },
    { name: '3 (days)', value: 259200 },
    { name: '4 (days)', value: 345600 },
    { name: '5 (days)', value: 432000 },
    { name: '6 (days)', value: 518400 },
    { name: '7 (days)', value: 604800 },
    { name: '8 (days)', value: 691200 },
    { name: '9 (days)', value: 777600 },
    { name: '10 (days)', value: 864000 },
    { name: '11 (days)', value: 950400 },
    { name: '12 (days)', value: 1036800 },
    { name: '13 (days)', value: 1123200 },
    { name: '14 (days)', value: 1209600 },
    { name: '15 (days)', value: 1296000 },
    { name: '16 (days)', value: 1382400 },
    { name: '17 (days)', value: 1468800 },
    { name: '18 (days)', value: 1555200 },
    { name: '19 (days)', value: 1641600 },
    { name: '20 (days)', value: 1728000 },
    { name: '21 (days)', value: 1814400 },
    { name: '22 (days)', value: 1900800 },
    { name: '23 (days)', value: 1987200 },
    { name: '24 (days)', value: 2073600 },
    { name: '25 (days)', value: 2160000 },
    { name: '26 (days)', value: 2246400 },
    { name: '27 (days)', value: 2332800 },
    { name: '28 (days)', value: 2419200 },
    { name: '29 (days)', value: 2505600 },
    { name: '30 (days)', value: 2592000 },
  ],
  attributionTimeMin: [
    { name: '0 (unlimited)', value: 0 },
    { name: '5 (seconds)', value: 5 },
    { name: '10 (seconds)', value: 10 },
    { name: '15 (seconds)', value: 15 },
    { name: '20 (seconds)', value: 20 },
    { name: '25 (seconds)', value: 25 },
    { name: '30 (seconds)', value: 30 },
    { name: '35 (seconds)', value: 35 },
    { name: '40 (seconds)', value: 40 },
    { name: '45 (seconds)', value: 45 },
    { name: '50 (seconds)', value: 50 },
    { name: '55 (seconds)', value: 55 },
    { name: '60 (seconds)', value: 60 },
    { name: '65 (seconds)', value: 65 },
    { name: '70 (seconds)', value: 70 },
    { name: '75 (seconds)', value: 75 },
    { name: '80 (seconds)', value: 80 },
    { name: '85 (seconds)', value: 85 },
    { name: '90 (seconds)', value: 90 },
    { name: '95 (seconds)', value: 95 },
    { name: '100 (seconds)', value: 100 },
    { name: '105 (seconds)', value: 105 },
    { name: '110 (seconds)', value: 110 },
    { name: '115 (seconds)', value: 115 },
    { name: '120 (seconds)', value: 120 },
  ],
});
