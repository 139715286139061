<template>
  <action-menu
    :actions="actions"
    :option-list-position="getTableItemPosition()"
    @click="onActionClick($event, item)"
    @dotsClicked="$emit('dotsClicked')"
  />
</template>

<script>
import ActionMenu from '@/components/organisms/shared/ActionMenu';

const DROPDOWN_POSITION = {
  BOTTOM: 'bottom',
  TOP: 'top',
  RIGHT: 'right',
  LEFT: 'left',
  LEFT_BOTTOM: 'leftBottom',
  LEFT_TOP: 'leftTop',
};

export default {
  name: 'TableActionMenu',
  components: { ActionMenu },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getTableItemPosition() {
      const itemPosition = this.items.indexOf(this.item);
      const middlePosition = this.items.length / 2;
      if (itemPosition >= middlePosition) {
        return DROPDOWN_POSITION.LEFT_TOP;
      }
      return DROPDOWN_POSITION.LEFT_BOTTOM;
    },
    onActionClick(event, item) {
      this.$emit('click', event, item);
    },
  },
};
</script>
