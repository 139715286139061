var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    scopedSlots: _vm._u([
      {
        key: "header-right",
        fn: function () {
          return [
            _c(
              "router-link",
              { attrs: { to: _vm.formRoute() } },
              [
                _c(
                  "sun-button",
                  {
                    staticClass:
                      "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                    attrs: { variant: "pill", color: "gray" },
                  },
                  [_vm._v(" + New Template ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("sun-data-table", {
              attrs: {
                content: _vm.items,
                headers: _vm.headers,
                loading: _vm.isLoading,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: `col.name`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c("sun-data-table-cell", { class: columnClass }, [
                          _vm.isCustomTemplate(item.id)
                            ? _c("span", { staticClass: "w-full" }, [
                                _vm._v(_vm._s(item.name)),
                              ])
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "w-full hover:underline cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick(
                                        { name: "Edit" },
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.creationDate`,
                    fn: function ({ item }) {
                      return [
                        _c("sun-data-table-cell", [
                          _c(
                            "div",
                            { staticClass: "flex flex-row justify-center" },
                            [
                              _c("date-tooltip", {
                                staticClass: "border-none",
                                attrs: { date: item.createdAt },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.actions`,
                    fn: function ({ item, columnClass }) {
                      return [
                        !_vm.isCustomTemplate(item.id)
                          ? _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.allowedActions(item),
                                    item: item,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("sun-pagination-page", {
              staticClass: "mb-8",
              attrs: {
                "total-pages": _vm.totalPages,
                "total-items": _vm.totalItems,
                "current-page": _vm.currentPage,
                value: _vm.itemsPerPage,
              },
              on: {
                changePage: _vm.changePage,
                changePerPage: _vm.changeItemsPerPage,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }