<template>
  <asterix-dropdown
    :option-list-position="optionListPosition"
    :options="actions"
    @change="onActionChanged"
    @clicked="$emit('dotsClicked')"
  >
    <template #default>
      <menu-dots-svg class="h-6 w-6 text-gray-700 transition-200 hover:bg-gray-400 cursor-pointer" />
    </template>
  </asterix-dropdown>
</template>

<script>
import MenuDotsSvg from '@/components/icons/MenuDotsSvg';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';

const DROPDOWN_POSITION = {
  BOTTOM: 'bottom',
  TOP: 'top',
  RIGHT: 'right',
  LEFT: 'left',
  LEFT_BOTTOM: 'leftBottom',
  LEFT_TOP: 'leftTop',
};

export default {
  name: 'ActionMenu',
  components: {
    AsterixDropdown,
    MenuDotsSvg,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    optionListPosition: {
      type: String,
      default: () => DROPDOWN_POSITION.LEFT_BOTTOM,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {},
  methods: {
    onOpen() {
      this.isOpen = true;
    },
    onClose() {
      this.isOpen = false;
    },
    onActionChanged(action) {
      this.isOpen = false;
      this.$emit('click', action);
    },
  },
};
</script>

<style scoped>
::v-deep #dropdown-indicator {
  display: none;
}
</style>
