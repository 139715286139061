<template>
  <div v-if="!isLoading">
    <ip-control :base-fraud="baseFraud" :disabled="isDisabled" @update="onFraudUpdate" />
    <app-control :base-fraud="baseFraud" :disabled="isDisabled" class="mt-6" @update="onFraudUpdate" />
    <anomalous-distribution :base-fraud="baseFraud" :disabled="isDisabled" class="mt-6" @update="onFraudUpdate" />
    <referer :base-fraud="baseFraud" :disabled="isDisabled" class="mt-6" @update="onFraudUpdate" />
    <div class="flex mt-12">
      <save-button
        :loading="isSaving"
        :disabled="isSaving || formErrors || isDisabled"
        text="Save"
        @click="submitForm"
      />
    </div>
  </div>
  <div v-else>
    <card-form-loading :rows="3" />
    <card-form-loading :rows="2" class="mt-6" />
    <card-form-loading :rows="2" class="mt-6" />
    <card-form-loading :rows="2" class="mt-6" />
  </div>
</template>

<script>
import IpControl from '@/components/molecules/modules/ecommerce/fraud/IpControl';
import AppControl from '@/components/molecules/modules/ecommerce/fraud/AppControl';
import AnomalousDistribution from '@/components/molecules/modules/ecommerce/fraud/AnomalousDistribution';
import Referer from '@/components/molecules/modules/ecommerce/fraud/Referer';
import SaveButton from '@/components/atoms/SaveButton';
import CardFormLoading from '@/components/atoms/CardFormLoading';

export default {
  name: 'Fraud',
  components: { IpControl, AppControl, AnomalousDistribution, Referer, SaveButton, CardFormLoading },
  props: {
    formMode: {
      type: String,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    formErrors: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    baseFraud: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isDisabled() {
      return this.baseFraud?.id === process.env.VUE_APP_FRAUD_TEMPLATE_CUSTOM_ID;
    },
  },
  methods: {
    onFraudUpdate(updatedFraud) {
      this.$emit('update', updatedFraud);
    },
    submitForm(fraud) {
      this.$emit('submit', fraud);
    },
  },
};
</script>
