var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "div",
        [
          _c("ip-control", {
            attrs: { "base-fraud": _vm.baseFraud, disabled: _vm.isDisabled },
            on: { update: _vm.onFraudUpdate },
          }),
          _c("app-control", {
            staticClass: "mt-6",
            attrs: { "base-fraud": _vm.baseFraud, disabled: _vm.isDisabled },
            on: { update: _vm.onFraudUpdate },
          }),
          _c("anomalous-distribution", {
            staticClass: "mt-6",
            attrs: { "base-fraud": _vm.baseFraud, disabled: _vm.isDisabled },
            on: { update: _vm.onFraudUpdate },
          }),
          _c("referer", {
            staticClass: "mt-6",
            attrs: { "base-fraud": _vm.baseFraud, disabled: _vm.isDisabled },
            on: { update: _vm.onFraudUpdate },
          }),
          _c(
            "div",
            { staticClass: "flex mt-12" },
            [
              _c("save-button", {
                attrs: {
                  loading: _vm.isSaving,
                  disabled: _vm.isSaving || _vm.formErrors || _vm.isDisabled,
                  text: "Save",
                },
                on: { click: _vm.submitForm },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("card-form-loading", { attrs: { rows: 3 } }),
          _c("card-form-loading", { staticClass: "mt-6", attrs: { rows: 2 } }),
          _c("card-form-loading", { staticClass: "mt-6", attrs: { rows: 2 } }),
          _c("card-form-loading", { staticClass: "mt-6", attrs: { rows: 2 } }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }