const TYPE_PIXELS = {
  IMG: 'IMG',
  S2S: 'S2S',
};
const TYPE_PIXEL_OPTIONS = [
  { name: 'Direct (image Script)', value: TYPE_PIXELS.IMG },
  { name: 'Server to Server', value: TYPE_PIXELS.S2S },
];
const UNIQUE_CONVERSION_OPTIONS = [
  { name: 'None', value: 0 },
  { name: 'Unique per Session', value: 1 },
  { name: 'Unique per User', value: 2 },
];

export { TYPE_PIXELS, UNIQUE_CONVERSION_OPTIONS };
export default TYPE_PIXEL_OPTIONS;
