var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "div",
        [
          _c("form-row", {
            scopedSlots: _vm._u(
              [
                {
                  key: "left",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Name" } },
                        [
                          _c("asterix-input", {
                            attrs: {
                              type: "text",
                              "validate-on-blur": "",
                              minlength: 3,
                              maxlength: 250,
                              "text-error": "This field is required.",
                              placeholder: "Template name...",
                              required: "required",
                              disabled: _vm.isDisabled,
                            },
                            on: { change: _vm.onUpdateFraud },
                            model: {
                              value: _vm.fraud.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.fraud, "name", $$v)
                              },
                              expression: "fraud.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { attrs: { text: "Unique Leads" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex w-full" },
                            [
                              _c("sun-select", {
                                attrs: {
                                  name: "unique-conversion",
                                  disabled: _vm.isDisabled,
                                  options: _vm.uniqueConversionOptions,
                                  label: "name",
                                  "track-by": "value",
                                  "close-on-select": "",
                                },
                                model: {
                                  value: _vm.uniqueConversion,
                                  callback: function ($$v) {
                                    _vm.uniqueConversion = $$v
                                  },
                                  expression: "uniqueConversion",
                                },
                              }),
                              _c("p", { staticClass: "group-description" }, [
                                _vm._v(_vm._s(_vm.uniqueLeadsText)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3040949569
            ),
          }),
          _c("form-row", {
            scopedSlots: _vm._u(
              [
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "sun-label-group",
                        { staticClass: "w-full", attrs: { text: "Frequency" } },
                        [
                          _c("sun-select", {
                            attrs: {
                              value: _vm.fraudFrecuency,
                              options: _vm.numOptions,
                              "add-hex-color": "orange",
                              required: "required",
                              disabled: _vm.isDisabled,
                            },
                            on: { change: _vm.frecuencyCpcChanged },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1003788395
            ),
          }),
          _c(
            "card-form",
            { staticClass: "mt-10" },
            [
              _c("template", { slot: "title" }, [_vm._v("Attribution")]),
              _c(
                "template",
                { slot: "form" },
                [
                  _c("form-row", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c("form-column", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "up",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex items-center",
                                            },
                                            [
                                              _c(
                                                "sun-label-group",
                                                {
                                                  attrs: { text: "Postclick" },
                                                },
                                                [
                                                  _c("sun-toggle", {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      name: "booked-volume-toggle",
                                                      disabled: "",
                                                    },
                                                    on: {
                                                      change: _vm.onUpdateFraud,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fraud
                                                          .offerAttribution
                                                          .postclick_active,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.fraud
                                                            .offerAttribution,
                                                          "postclick_active",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fraud.offerAttribution.postclick_active",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "sun-label-group",
                                                {
                                                  staticClass: "flex-1 ml-8",
                                                  attrs: {
                                                    text: "Length of time",
                                                  },
                                                },
                                                [
                                                  _c("sun-select", {
                                                    attrs: {
                                                      options:
                                                        _vm.lengthOfTimeOptions,
                                                      "track-by": "value",
                                                      label: "name",
                                                      "close-on-select": "",
                                                      disabled: _vm.isDisabled,
                                                    },
                                                    on: {
                                                      change: _vm.onUpdateFraud,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.lengthOfTimeForPostclick,
                                                      callback: function ($$v) {
                                                        _vm.lengthOfTimeForPostclick =
                                                          $$v
                                                      },
                                                      expression:
                                                        "lengthOfTimeForPostclick",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "down",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex items-center",
                                            },
                                            [
                                              _c(
                                                "sun-label-group",
                                                { attrs: { text: "Postview" } },
                                                [
                                                  _c("sun-toggle", {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      name: "attribution-postview-toggle",
                                                      disabled: _vm.isDisabled,
                                                    },
                                                    on: {
                                                      change: _vm.onUpdateFraud,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fraud
                                                          .offerAttribution
                                                          .postview_active,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.fraud
                                                            .offerAttribution,
                                                          "postview_active",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fraud.offerAttribution.postview_active",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "sun-label-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.fraud
                                                          .offerAttribution,
                                                      expression:
                                                        "fraud.offerAttribution",
                                                    },
                                                  ],
                                                  staticClass: "flex-1 ml-8",
                                                  attrs: {
                                                    text: "Length of time",
                                                  },
                                                },
                                                [
                                                  _c("sun-select", {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.fraud
                                                          .offerAttribution
                                                          .postview_active ||
                                                        _vm.isDisabled,
                                                      options:
                                                        _vm.lengthOfTimeOptions,
                                                      "track-by": "value",
                                                      label: "name",
                                                      "close-on-select": "",
                                                    },
                                                    on: {
                                                      change: _vm.onUpdateFraud,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.lengthOfTimeForPostview,
                                                      callback: function ($$v) {
                                                        _vm.lengthOfTimeForPostview =
                                                          $$v
                                                      },
                                                      expression:
                                                        "lengthOfTimeForPostview",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  341939904
                                ),
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "sm:border-t-2 sm:pt-4 lg:border-l-2 lg:border-t-0 lg:pl-8 lg:pt-0",
                                },
                                [
                                  _c("form-column", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "up",
                                          fn: function () {
                                            return [
                                              _c(
                                                "sun-label-group",
                                                {
                                                  attrs: {
                                                    text: "Attribution time min",
                                                  },
                                                },
                                                [
                                                  _c("sun-select", {
                                                    attrs: {
                                                      value: _vm.offerMinTime,
                                                      options:
                                                        _vm.attrTimeMinOption,
                                                      label: "name",
                                                      "track-by": "value",
                                                      "close-on-select": "",
                                                      required: "required",
                                                      "text-error":
                                                        "This field is required",
                                                      disabled: _vm.isDisabled,
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.onMinTimeChange,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "down",
                                          fn: function () {
                                            return [
                                              _c(
                                                "sun-label-group",
                                                {
                                                  attrs: {
                                                    text: "Attribution time out",
                                                  },
                                                },
                                                [
                                                  _c("sun-select", {
                                                    attrs: {
                                                      value: _vm.offerMaxTime,
                                                      options:
                                                        _vm.attrTimeOutOption,
                                                      label: "name",
                                                      "track-by": "value",
                                                      "close-on-select": "",
                                                      required: "required",
                                                      "text-error":
                                                        "This field is required",
                                                      disabled: _vm.isDisabled,
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.onMaxTimeChange,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      772788286
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1196106439
                    ),
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "flex mt-12" },
            [
              _c("save-button", {
                attrs: {
                  loading: _vm.isSaving,
                  disabled: _vm.isSaving || _vm.formErrors || _vm.isDisabled,
                  text: "Save",
                },
                on: { click: _vm.submitForm },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _c("div", [_c("card-form-loading", { attrs: { rows: 3 } })], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }