<template>
  <div v-if="!isLoading">
    <form-row>
      <template #left>
        <sun-label-group text="Name">
          <asterix-input
            v-model="fraud.name"
            type="text"
            validate-on-blur
            :minlength="3"
            :maxlength="250"
            text-error="This field is required."
            placeholder="Template name..."
            required="required"
            :disabled="isDisabled"
            @change="onUpdateFraud"
          />
        </sun-label-group>
      </template>
      <template #right>
        <sun-label-group text="Unique Leads">
          <div class="flex w-full">
            <sun-select
              v-model="uniqueConversion"
              name="unique-conversion"
              :disabled="isDisabled"
              :options="uniqueConversionOptions"
              label="name"
              track-by="value"
              close-on-select
            >
            </sun-select>
            <p class="group-description">{{ uniqueLeadsText }}</p>
          </div>
        </sun-label-group>
      </template>
    </form-row>
    <form-row>
      <template #right>
        <sun-label-group text="Frequency" class="w-full">
          <sun-select
            :value="fraudFrecuency"
            :options="numOptions"
            add-hex-color="orange"
            required="required"
            :disabled="isDisabled"
            @change="frecuencyCpcChanged"
          />
        </sun-label-group>
      </template>
    </form-row>
    <card-form class="mt-10">
      <template slot="title">Attribution</template>
      <template slot="form">
        <form-row>
          <template #left>
            <form-column>
              <template #up>
                <div class="flex items-center">
                  <sun-label-group text="Postclick">
                    <sun-toggle
                      v-model="fraud.offerAttribution.postclick_active"
                      class="ml-2"
                      name="booked-volume-toggle"
                      disabled
                      @change="onUpdateFraud"
                    />
                  </sun-label-group>
                  <sun-label-group text="Length of time" class="flex-1 ml-8">
                    <sun-select
                      v-model="lengthOfTimeForPostclick"
                      :options="lengthOfTimeOptions"
                      track-by="value"
                      label="name"
                      close-on-select
                      :disabled="isDisabled"
                      @change="onUpdateFraud"
                    />
                  </sun-label-group>
                </div>
              </template>
              <template #down>
                <div class="flex items-center">
                  <sun-label-group text="Postview">
                    <sun-toggle
                      v-model="fraud.offerAttribution.postview_active"
                      class="ml-2"
                      name="attribution-postview-toggle"
                      :disabled="isDisabled"
                      @change="onUpdateFraud"
                    />
                  </sun-label-group>
                  <sun-label-group v-show="fraud.offerAttribution" text="Length of time" class="flex-1 ml-8">
                    <sun-select
                      v-model="lengthOfTimeForPostview"
                      :disabled="!fraud.offerAttribution.postview_active || isDisabled"
                      :options="lengthOfTimeOptions"
                      track-by="value"
                      label="name"
                      close-on-select
                      @change="onUpdateFraud"
                    />
                  </sun-label-group>
                </div>
              </template>
            </form-column>
          </template>
          <template #right>
            <div class="sm:border-t-2 sm:pt-4 lg:border-l-2 lg:border-t-0 lg:pl-8 lg:pt-0">
              <form-column>
                <template #up>
                  <sun-label-group text="Attribution time min">
                    <sun-select
                      :value="offerMinTime"
                      :options="attrTimeMinOption"
                      label="name"
                      track-by="value"
                      close-on-select
                      required="required"
                      text-error="This field is required"
                      :disabled="isDisabled"
                      @input="onMinTimeChange"
                    />
                  </sun-label-group>
                </template>
                <template #down>
                  <sun-label-group text="Attribution time out">
                    <sun-select
                      :value="offerMaxTime"
                      :options="attrTimeOutOption"
                      label="name"
                      track-by="value"
                      close-on-select
                      required="required"
                      text-error="This field is required"
                      :disabled="isDisabled"
                      @input="onMaxTimeChange"
                    />
                  </sun-label-group>
                </template>
              </form-column>
            </div>
          </template>
        </form-row>
      </template>
    </card-form>
    <div class="flex mt-12">
      <save-button
        :loading="isSaving"
        :disabled="isSaving || formErrors || isDisabled"
        text="Save"
        @click="submitForm"
      />
    </div>
  </div>
  <div v-else>
    <card-form-loading :rows="3" />
  </div>
</template>

<script>
import { fraudFormMixin } from '@/mixins/modules/ecommerce/fraudFormMixin';
import { deepClone } from '@/utils/deepClone';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import CardForm from '@/components/atoms/CardForm';
import FormColumn from '@/components/atoms/FormColumn/FormColumn';
import CONFIG from '@/components/molecules/modules/ecommerce/offer/form/attribution/config';
import SaveButton from '@/components/atoms/SaveButton';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import { UNIQUE_CONVERSION_OPTIONS } from '@/model/modules/ecommerce/offer/TypePixelOptions';

export default {
  name: 'BasicInfo',
  components: { FormRow, AsterixInput, CardForm, FormColumn, SaveButton, CardFormLoading },
  mixins: [fraudFormMixin],
  props: {
    formMode: {
      type: String,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    formErrors: {
      type: Boolean,
      default: false,
    },
    baseFraud: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      lengthOfTimeOptions: CONFIG.lengthOfTimeOptions,
      attrTimeMinOption: CONFIG.attributionTimeMin,
      attrTimeOutOption: CONFIG.attributionTimeOut,
      fraud: null,
      numOptions: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      uniqueConversionOptions: UNIQUE_CONVERSION_OPTIONS,
    };
  },
  computed: {
    fraudFrecuency() {
      return this.fraud?.uniqueUserDailyCapping?.toString() || '0';
    },
    lengthOfTimeForPostview: {
      get() {
        const { postview_value } = this.fraud.offerAttribution;
        return CONFIG.lengthOfTimeOptions.find(({ value }) => value === postview_value);
      },
      set({ value }) {
        this.fraud.offerAttribution.postview_value = value || 0;
      },
    },
    lengthOfTimeForPostclick: {
      get() {
        const { postclick_value } = this.fraud.offerAttribution;
        return CONFIG.lengthOfTimeOptions.find(({ value }) => value === postclick_value);
      },
      set({ value }) {
        this.fraud.offerAttribution.postclick_value = value || 0;
      },
    },
    uniqueConversion: {
      get() {
        return this.uniqueConversionOptions.find(({ value }) => value === this.fraud.uniqueConversion);
      },
      set(conversion) {
        this.fraud.uniqueConversion = conversion?.value || 0;
        this.onUpdateFraud();
      },
    },
    offerMaxTime() {
      return this.attrTimeOutOption.find(opt => opt.value === this.fraud.offerAttribution.maxAttributionTime);
    },
    offerMinTime() {
      return this.attrTimeMinOption.find(opt => opt.value === this.fraud.offerAttribution.minAttributionTime);
    },
    isDisabled() {
      return this.baseFraud?.id === process.env.VUE_APP_FRAUD_TEMPLATE_CUSTOM_ID;
    },
  },
  watch: {
    baseFraud: {
      deep: true,
      handler(updatedFraud) {
        this.fraud = deepClone(updatedFraud);
      },
    },
  },
  created() {
    this.fraud = deepClone(this.baseFraud);
  },
  methods: {
    frecuencyCpcChanged({ value }) {
      const number = parseInt(value, 10);
      this.fraud.uniqueUserDailyCapping = number || number === 0 ? number : null;
      this.onUpdateFraud();
    },
    onMaxTimeChange(event) {
      if (event) {
        this.fraud.offerAttribution.maxAttributionTime = event.value;
      } else {
        this.fraud.offerAttribution.maxAttributionTime = null;
      }
      this.onUpdateFraud();
    },
    onMinTimeChange(event) {
      if (event) {
        this.fraud.offerAttribution.minAttributionTime = event.value;
      } else {
        this.fraud.offerAttribution.minAttributionTime = null;
      }
      this.onUpdateFraud();
    },
    submitForm() {
      this.$emit('submit');
    },
  },
};
</script>
<style scoped>
.group-description {
  @apply ml-2 text-sm italic;
}
</style>
