<template>
  <asterix-section>
    <template #header-right>
      <router-link :to="formRoute()">
        <sun-button variant="pill" class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800" color="gray">
          + New Template
        </sun-button>
      </router-link>
    </template>
    <template #content>
      <sun-data-table :content="items" :headers="headers" :loading="isLoading">
        <template #[`col.name`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            <span v-if="isCustomTemplate(item.id)" class="w-full">{{ item.name }}</span>
            <span v-else class="w-full hover:underline cursor-pointer" @click="onActionClick({ name: 'Edit' }, item)">
              {{ item.name }}
            </span>
          </sun-data-table-cell>
        </template>
        <template #[`col.creationDate`]="{ item }">
          <sun-data-table-cell>
            <div class="flex flex-row justify-center">
              <date-tooltip class="border-none" :date="item.createdAt" />
            </div>
          </sun-data-table-cell>
        </template>
        <template #[`col.actions`]="{ item, columnClass }">
          <sun-data-table-cell v-if="!isCustomTemplate(item.id)" :class="columnClass">
            <table-action-menu :actions="allowedActions(item)" :item="item" @click="onActionClick($event, item)" />
          </sun-data-table-cell>
        </template>
      </sun-data-table>
      <sun-pagination-page
        class="mb-8"
        :total-pages="totalPages"
        :total-items="totalItems"
        :current-page="currentPage"
        :value="itemsPerPage"
        @changePage="changePage"
        @changePerPage="changeItemsPerPage"
      />
    </template>
  </asterix-section>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import AsterixSection from '@/components/templates/AsterixSection';
import DateTooltip from '@/components/atoms/DateTooltip/DateTooltip';
import TableActionMenu from '@/components/organisms/shared/TableActionMenu';
import { getFraudTemplates, deleteFraudTemplate } from '@/services/modules/ecommerce/fraudTemplate';
import { TABLE_CONFIG } from '@/views/private/modules/ecommerce/fraudTemplates/config';
import { create as fraudTemplateCreate } from '@/router/private/modules/ecommerce/admin/fraudTemplates/create';
import { edit as fraudTemplateEdit } from '@/router/private/modules/ecommerce/admin/fraudTemplates/edit';
import { duplicate as fraudTemplateDuplicate } from '@/router/private/modules/ecommerce/admin/fraudTemplates/duplicate';
import { indexMixin } from '@/mixins/index/indexMixin';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export default {
  name: 'FraudTemplates',
  components: { AsterixSection, DateTooltip, TableActionMenu },
  mixins: [indexMixin],
  data() {
    return {
      actions: TABLE_CONFIG.actions,
      items: [],
      headers: TABLE_CONFIG.defaultColumns,
      isLoading: false,
      fraudTemplateCreate,
      fraudTemplateEdit,
      fraudTemplateDuplicate,
    };
  },
  async created() {
    await this.getFraudTemplates();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async getFraudTemplates() {
      this.isLoading = true;
      try {
        const params = this.setParamsToTable();
        const { data, meta } = await getFraudTemplates(params);
        this.items = data;
        this.totalPages = meta?.totalPages || 1;
        this.totalItems = meta?.totalResults || data?.length;
      } catch (error) {
        this.createToast(Toast.error(`We couldn't get fraud templates`, error.message));
      } finally {
        this.isLoading = false;
      }
    },
    setParamsToTable() {
      const auxParams = new QueryParamsBuilder(this.currentPage, this.itemsPerPage);
      if (this.sort && this.sort.field) {
        auxParams.addSort(this.sort.field, this.sort.order?.toUpperCase());
      } else if (typeof this.sort === 'string') {
        auxParams.sort = this.sort;
      } else if (this.defaultSort?.field) {
        auxParams.addSort(this.defaultSort.field, this.defaultSort.order);
      }
      return auxParams;
    },
    formRoute(entity, isEdit = false) {
      let routeName;
      let formMode;
      if (!entity) {
        routeName = this.fraudTemplateCreate.name;
        formMode = 'new';
      } else if (isEdit) {
        routeName = this.fraudTemplateEdit.name;
        formMode = 'edit';
      } else {
        routeName = this.fraudTemplateDuplicate.name;
        formMode = 'duplicate';
      }
      return {
        name: routeName,
        params: { fraudTemplateId: entity?.id, formMode, basicFraudTemplate: entity },
      };
    },
    allowedActions(item) {
      if (item.name === 'Custom') {
        return this.actions.filter(action => action.name === 'Duplicate');
      }
      if (item.countOffers > 0) {
        return this.actions.filter(action => action.name !== 'Delete');
      }
      return this.actions;
    },
    async changePage(page) {
      this.currentPage = page;
      this.addQueryParams({ page });
      await this.queryParamsRouterReplace();
      await this.getFraudTemplates();
    },
    async onActionClick(event, item) {
      switch (event.name) {
        case 'Edit':
          this.$router.push(this.formRoute(item, true));
          break;
        case 'Duplicate':
          this.$router.push(this.formRoute(item));
          break;
        case 'Delete':
          try {
            await deleteFraudTemplate(item.id);
            this.createToast(Toast.error(`Template ${item.name}`, 'Deleted successfully'));
            await this.getFraudTemplates();
          } catch (error) {
            this.createToast(Toast.error(`We couldn't get fraud templates`, error.message));
          }
          break;
      }
    },
    isCustomTemplate(id) {
      return id === process.env.VUE_APP_FRAUD_TEMPLATE_CUSTOM_ID;
    },
  },
};
</script>
