var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("action-menu", {
    attrs: {
      actions: _vm.actions,
      "option-list-position": _vm.getTableItemPosition(),
    },
    on: {
      click: function ($event) {
        return _vm.onActionClick($event, _vm.item)
      },
      dotsClicked: function ($event) {
        return _vm.$emit("dotsClicked")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }