<template>
  <div>
    <meta-actions-header class="mb-12" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1" :class="{ 'text-gray-700': !isNewTemplate }">
          <span v-if="!isNewTemplate">: </span>
          <span v-if="!isNewTemplate" class="text-orange-500"> {{ template.name }}</span>
        </breadcrumb>
      </template>
    </meta-actions-header>
    <tabs
      :tabs-data="tabs"
      :base-fraud="template"
      :form-mode="formMode"
      :is-saving="isSaving"
      :is-loading="isLoading"
      :form-errors="formErrors"
      @update="onFraudUpdate"
      @submit="onSubmitForm"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import Tabs from '@/components/atoms/Tabs';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import FraudTemplate from '@/entities/ecommerce/fraud/FraudTemplate';
import { TABS_CONFIG, CUSTOM_TEMPLATE } from '@/views/private/modules/ecommerce/fraudTemplates/config';
import { deepClone } from '@/utils/deepClone';
import {
  createFraudTemplate,
  updateFraudTemplate,
  getFraudTemplateById,
} from '@/services/modules/ecommerce/fraudTemplate';
import { fraudTemplates } from '@/router/private/modules/ecommerce/admin/fraudTemplates';

export default {
  name: 'FraudTemplatesForm',
  components: { MetaActionsHeader, Breadcrumb, Tabs },
  props: {
    formMode: {
      type: String,
      default: null,
    },
    fraudTemplateId: {
      type: String,
      default: null,
    },
    basicFraudTemplate: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    isSaving: false,
    template: null,
  }),
  computed: {
    isNewTemplate() {
      return this.formMode === 'new';
    },
    isEditingTemplate() {
      return this.formMode === 'edit';
    },
    isDuplicateTemplate() {
      return this.formMode === 'duplicate';
    },
    formErrors() {
      if (!this.template) return false;
      const proxyUsageType = !this.template.proxyUsageType.leadClick || !this.template.proxyUsageType.leadConversion;
      const usageType =
        !this.template.usageType.click ||
        !this.template.usageType.lead_click ||
        !this.template.usageType.lead_conversion;
      const nameError = !this.template.name || this.template.name.length < 3 || this.template.name === CUSTOM_TEMPLATE;
      const frecuencyCapError = this.template.uniqueUserDailyCapping === null;

      return nameError || frecuencyCapError || usageType || proxyUsageType;
    },
    tabs() {
      return TABS_CONFIG[this.formMode].tabs;
    },
  },
  async created() {
    await this.loadFraudTemplate();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async loadFraudTemplate() {
      try {
        this.isLoading = true;
        if (this.isNewTemplate) {
          this.template = new FraudTemplate();
        } else if (this.basicFraudTemplate) {
          const basicTemplate = deepClone(this.basicFraudTemplate);
          if (this.isDuplicateTemplate) {
            basicTemplate.name = null;
            basicTemplate.id = null;
          }
          this.template = basicTemplate;
        } else {
          const { data } = await getFraudTemplateById(this.fraudTemplateId);
          const basicTemplate = data;
          if (this.isDuplicateTemplate) {
            basicTemplate.name = null;
            basicTemplate.id = null;
          }
          this.template = basicTemplate;
        }
      } catch (error) {
        this.createToast(Toast.error(`We couldn't load template`, error.message));
        this.$router.push({ name: fraudTemplates.name });
      } finally {
        this.isLoading = false;
      }
    },
    onFraudUpdate(updatedFraud) {
      const newTemplate = deepClone(updatedFraud);
      newTemplate.usageType = updatedFraud.usageType;
      newTemplate.proxyUsageType = updatedFraud.proxyUsageType;
      newTemplate.ipMissMatch = updatedFraud.ipMissMatch;
      newTemplate.controlApps = updatedFraud.controlApps;
      newTemplate.controlUserAgents = updatedFraud.controlUserAgents;
      newTemplate.offerAttribution = updatedFraud.offerAttribution;
      newTemplate.referer = updatedFraud.referer;
      this.template = newTemplate;
    },
    async onSubmitForm() {
      this.isSaving = true;
      try {
        if (this.isNewTemplate || this.isDuplicateTemplate) {
          await createFraudTemplate(this.template);
          this.createToast(Toast.success(`Fraud Template created successfully`, this.template.name));
        } else {
          await updateFraudTemplate(this.template);
          this.createToast(Toast.success(`Fraud Template updated successfully`, this.template.name));
        }
        this.$router.push({ name: fraudTemplates.name });
      } catch (error) {
        this.createToast(Toast.error(`We couldn't create template`, error.message));
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
