var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-dropdown", {
    attrs: {
      "option-list-position": _vm.optionListPosition,
      options: _vm.actions,
    },
    on: {
      change: _vm.onActionChanged,
      clicked: function ($event) {
        return _vm.$emit("dotsClicked")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("menu-dots-svg", {
              staticClass:
                "h-6 w-6 text-gray-700 transition-200 hover:bg-gray-400 cursor-pointer",
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }